import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"

const IndexPage = () => (
  <Layout>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <h2>Micha "mies" Hernandez van Leuffen</h2>
    <h3>make all the dust that you can</h3>
    <p>
      I am currently working on <a href="https://fiberplane.dev">Fiberplane</a>: collaborative notebooks for SREs, purpose-built for teams. You can read more about it <a href="https://medium.com/fiberplane/meet-fiberplane-d0e999425723">here</a>.
    </p>
    <p>
      I previously founded <a href="http://wercker.com">Wercker</a>, a
      container-native continuous delivery platform, which was acquired by
      Oracle in 2017.
    </p>
    <p>
      At Oracle I was a VP of Software Development working on their cloud native
      offerings and open source projects.
    </p>
    <p>I like startups and occasionally invest in them or help out.</p>
      Find me on&nbsp;<a href="https://twitter.com/mies">Twitter</a> and <a href="https://github.com/mies/">GitHub</a>.
  </Layout>
)

export default IndexPage
